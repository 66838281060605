import React, { FC, Fragment, ReactNode } from 'react';

import CookieBanner from '../CookieBanner';
import HeadTags from '../HeadTags';
// import SalesforceChatButton from './SalesforceChatButton';
import SegmentSnippet from '../SegmentSnippet';

import SalesforceChatButton2025 from '../SalesforceChatButton2025';

import Header from './Header';
import Footer from './Footer';
import { useStore } from '../../contexts/storeContext';

const DISMISSED_COOKIE_BANNER_COOKIE_NAME = 'CookieConsent';

interface LayoutWithHeaderAndFooterProps {}

const LayoutWithHeaderAndFooter: FC<LayoutWithHeaderAndFooterProps> = ({ children }) => {
  const { config, i18nUtils } = useStore();
  const isSalesforceChatVisible = config.getValue(
    'locale.salesforce-chat-enabled',
    i18nUtils.umRegion,
  );

  return (
    <Fragment>
      <HeadTags />
      <Header />
      <CookieBanner cookieName={DISMISSED_COOKIE_BANNER_COOKIE_NAME} />
      <div>{children}</div>
      {isSalesforceChatVisible && <SalesforceChatButton2025 />}
      {/* {isSalesforceChatVisible && <SalesforceChatButton />} */}
      <SegmentSnippet />
      <Footer />
    </Fragment>
  );
};

export default LayoutWithHeaderAndFooter;
